import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import HeroChirurgoContainer from "../components/HeroChirurgoContainer/HeroChirurgoContainer"
import ChirurgoMenu from "../components/ChirurgoMenu/ChirurgoMenu"
import ChirurgoInnerContainer from "../components/ChirurgoInnerContainer/ChirurgoInnerContainer"
import ChirurgoTrattamnetiContainer from "../components/ChirurgoTrattamentiContainer/ChirurgoTrattamentiContainer"
import ChirurgoMenuSticky from "../components/ChirurgoMenuSticky/ChirurgoMenuSticky"
import HeroChirurgoMobile from "../components/HeroChirurgoMobile/HeroChirurgoMobile"
import ChirurgoMobileButtons from "../components/ChirurgoMobileButtons/ChirurgoMobileButtons"

const descrizioneMock =
  "Lorem ipsum dolor sit amet, consecteur adipiscing elit. Pellentesque lacinia nunc vel commodo ultrices. Nulla vehicula, ex sed tempus pharetra, dolor tincidunt magna, ut ornare risus nisl quis arcum. Duis nec euisomd dui a mollis"

const ChirurgoPage = ({ location }) => {
  return null
}

export default ChirurgoPage
